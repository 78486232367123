let toastReference: HTMLElement | null

export function toggleToast(html?: string) {
  removeToast()
  if (!html) return

  const element = document.createElement('div')
  element.innerHTML = html
  if (document.body) document.body.append(element)

  const close = element.querySelector('button')
  if (close) {
    close.addEventListener('click', removeToast, {once: true})
  }

  document.addEventListener('keydown', (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      if (removeToast()) event.stopImmediatePropagation()
    }
  })

  toastReference = element
}

function removeToast(): boolean {
  if (!toastReference) return false
  toastReference.remove()
  toastReference = null
  return true
}
